import {
  Button,
  Card,
  CardBody,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Map from "../Map/Map";
import { MdOutlineMail, MdEmail } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import emailjs from "@emailjs/browser";
import "./Contact.css";
const Contact = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    companyName: "",
  });
  const handleChange = (e) => {
    const newInput = { [e.target.name]: e.target.value };
    setFormData({ ...formData, ...newInput });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    emailjs
      .sendForm(
        "service_paebkvm",
        "template_6v9fstd",
        e.target,
        "V_D-ZcbBm9c9jNmYN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <React.Fragment>
      <div className="contact">
        <div className="contact__background">
          <div className="contact__text">
            <h2>Contact Us</h2>
            <p>
              We’d love to learn more about how we can partner with your
              organization. Tell us a bit about yourself,and we’ll get in touch
              as soon as we can.
            </p>
          </div>
        </div>
        <div className="contact__flex">
          <div className="contact__message">
            <h4>Send Us a Message</h4>
            <Card maxW="md" shadow={"lg"} className="card">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={5}>
                    <Input
                      type={"text"}
                      colorScheme="whatsapp"
                      variant="flushed"
                      name="fullname"
                      placeholder="Fullname"
                      required
                      onChange={handleChange}
                    />
                    <Input
                      type={"email"}
                      variant="flushed"
                      placeholder="Email"
                      name="email"
                      required
                      onChange={handleChange}
                    />
                    <Input
                      type={"tel"}
                      variant="flushed"
                      name="phoneNumber"
                      placeholder="Mobile Phone Number"
                      required
                      onChange={handleChange}
                    />
                    <Input
                      type={"text"}
                      variant="flushed"
                      name="companyName"
                      placeholder="Company Name"
                      required
                      onChange={handleChange}
                    />
                    <Textarea
                      placeholder="Enter your Message here"
                      name="message"
                      required
                    />
                  </Stack>
                  <Stack mt={5} spacing={4}>
                    <Button
                      rightIcon={<MdOutlineMail fontSize={18} />}
                      colorScheme="whatsapp"
                      variant="outline"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              </CardBody>
            </Card>
          </div>
          <div className="contact__map">
            <Map />
            <div className="contact__details">
              <div className="phone">
                <TbPhoneCall fontSize={35} /> <span>+34642583766</span>
              </div>
              <div className="email">
                <MdEmail fontSize={35} />
                <div className="emails">
                  <span>info@finbarn.com</span>
                  <span>wepokennedy200@yahoo.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
